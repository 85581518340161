export const AVATAR_WRAPPER_ELEMENT_CLASS_NAME = 'avatar-wrapper';
export const AVATAR_MASK_ELEMENT_CLASS_NAME = 'avatar-mask';
export const AVATAR_ELEMENT_CLASS_NAME = 'avatar';

export const AVATAR_ELEMENT_HTML = `
<template id="avatar-template">
  <style>
    :host {
      position: relative;
      display: inline-block;
      font-size: 0;

      line-height: normal;
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;

      --shop-avatar-size: 32px;
    }

    .${AVATAR_WRAPPER_ELEMENT_CLASS_NAME} {
      --normalized-size: clamp(24px, var(--shop-avatar-size), 32px);
      font: 600 16px/19px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica;
      display: inline-block;
      border-radius: 100%;
      padding: calc(var(--normalized-size) * .1875);
    }

    .${AVATAR_WRAPPER_ELEMENT_CLASS_NAME}--masked {
      --normalized-size: clamp(24px, var(--shop-avatar-size), 32px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .${AVATAR_WRAPPER_ELEMENT_CLASS_NAME}:hover {
      background-color: rgb(242 244 245 / 1);
    }

    .${AVATAR_MASK_ELEMENT_CLASS_NAME} {
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
    }

    .${AVATAR_ELEMENT_CLASS_NAME} {
      font-size: calc(var(--normalized-size) / 2);
      box-sizing: border-box;
      display: flex;
      width: var(--normalized-size);
      height: var(--normalized-size);
      align-items: center;
      justify-content: center;
      border-radius: 9999999px;
      border: 1px solid rgb(227 227 227/1);
      background-color: rgb(242 244 245/1);
      color: rgb(18 18 18/1);
      background-size: cover;
      background-position: center;
      text-transform: uppercase;
      padding-left: 1px;
      padding-bottom: 1px;
    }

    @media (min-width: 750px) {
      .${AVATAR_WRAPPER_ELEMENT_CLASS_NAME}--masked {
        --normalized-size: clamp(28px, var(--shop-avatar-size), 32px);
      }
    }
  </style>
  <div class="${AVATAR_WRAPPER_ELEMENT_CLASS_NAME}">
    <div class="${AVATAR_ELEMENT_CLASS_NAME}"></div>
  </div>
  <div class="${AVATAR_MASK_ELEMENT_CLASS_NAME}"><slot></slot></div>
</template>
`;
