import {colors} from '../colors';

export class ChevronDown extends HTMLElement {
  constructor() {
    super();

    const template = document.createElement('template');
    const size = this.getAttribute('size') || '';
    template.innerHTML = getTemplateContents(size);

    this.attachShadow({mode: 'open'}).appendChild(
      template.content.cloneNode(true),
    );
  }
}

/**
 * @param {string} size the size of the icon
 * @returns {string} HTML content for the icon.
 */
function getTemplateContents(size: string) {
  return `
    <style>
      svg path {
        fill: var(--icon-color, ${colors.close})
      }
    </style>
    <svg
      width="${size}"
      height="${size}"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 14a.997.997 0 01-.707-.293l-5-5a.999.999 0 111.414-1.414L10 11.586l4.293-4.293a.999.999 0 111.414 1.414l-5 5A.997.997 0 0110 14" fill-rule="evenodd"/>
    </svg>
  `;
}
