import {AVATAR_WRAPPER_ELEMENT_CLASS_NAME} from '../constants';
import {
  AvatarTemplateElements,
  renderAvatarTemplate,
} from '../utils/renderAvatarTemplate';

export class AvatarView {
  private _elements: AvatarTemplateElements;
  private _resizeObserver: ResizeObserver;
  private _slot: HTMLSlotElement;
  private _hijackSize = 32;
  private _hijackEnabled = false;

  constructor(
    private _avatarSrc = '',
    private _initial = '',
    renderRoot: ShadowRoot,
  ) {
    this._elements = renderAvatarTemplate(renderRoot);
    this._resizeObserver = new ResizeObserver(
      this._synchronizeIconSize.bind(this),
    );

    this._slot = this._elements.mask.querySelector('slot')!;
    this._toggleViewMode();
    this._slot.addEventListener('slotchange', this._toggleViewMode.bind(this));
  }

  updateAvatarSrc(avatarSrc: string) {
    this._avatarSrc = avatarSrc;
    this._refresh();
  }

  updateInitial(initial: string) {
    this._initial = initial;
    this._refresh();
  }

  render() {
    this._resizeObserver.observe(this._elements.mask);
    this._refresh();
  }

  destroy() {
    this._resizeObserver.disconnect();
  }

  private _refresh() {
    if (this._avatarSrc) {
      this._elements.avatar.textContent = '';
      this._elements.avatar.style.backgroundImage = `url(${this._avatarSrc})`;
    } else {
      this._elements.avatar.style.backgroundImage = '';
      this._elements.avatar.textContent = this._initial;
    }

    this._elements.wrapper.classList.toggle(
      `${AVATAR_WRAPPER_ELEMENT_CLASS_NAME}--masked`,
      this._hijackEnabled,
    );

    if (this._hijackEnabled) {
      this._elements.wrapper.style.setProperty(
        '--shop-avatar-size',
        `${this._hijackSize}px`,
      );
    } else {
      this._elements.wrapper.style.removeProperty('--shop-avatar-size');
    }
  }

  private _synchronizeIconSize(entries: ResizeObserverEntry[]) {
    const [entry] = entries;

    if (!entry) {
      return;
    }

    const {height, width} = entry.contentRect;
    this._hijackSize = Math.min(height, width);
    this._refresh();
  }

  private _toggleViewMode() {
    this._hijackEnabled = this._slot.assignedElements().length > 0;
    this._refresh();
  }
}
