import {copyTemplateToDom} from '../../../common/utils';
import {
  AVATAR_ELEMENT_CLASS_NAME,
  AVATAR_ELEMENT_HTML,
  AVATAR_MASK_ELEMENT_CLASS_NAME,
  AVATAR_WRAPPER_ELEMENT_CLASS_NAME,
} from '../constants';

export interface AvatarTemplateElements {
  mask: HTMLDivElement;
  avatar: HTMLDivElement;
  wrapper: HTMLDivElement;
}

/**
 * Utility to copy template into the shadow root, render the template, and identify the key
 * elements in the template.
 * @param {ShadowRoot} renderRoot - The shadow root to render the template into.
 * @returns {AvatarTemplateElements} - The key elements in the template.
 */
export function renderAvatarTemplate(
  renderRoot: ShadowRoot,
): AvatarTemplateElements {
  copyTemplateToDom(AVATAR_ELEMENT_HTML, 'avatar-template', renderRoot);

  const avatar = renderRoot.querySelector(
    `.${AVATAR_ELEMENT_CLASS_NAME}`,
  ) as HTMLDivElement;
  const mask = renderRoot.querySelector(
    `.${AVATAR_MASK_ELEMENT_CLASS_NAME}`,
  ) as HTMLDivElement;
  const wrapper = renderRoot.querySelector(
    `.${AVATAR_WRAPPER_ELEMENT_CLASS_NAME}`,
  ) as HTMLDivElement;

  return {avatar, mask, wrapper};
}
