import {colors} from '../colors';

export class ChevronUp extends HTMLElement {
  constructor() {
    super();

    const template = document.createElement('template');
    const size = this.getAttribute('size') || '';
    template.innerHTML = getTemplateContents(size);

    this.attachShadow({mode: 'open'}).appendChild(
      template.content.cloneNode(true),
    );
  }
}

/**
 * @param {string} size the size of the icon
 * @returns {string} HTML content for the icon.
 */
function getTemplateContents(size: string) {
  return `
    <style>
      svg path {
        fill: var(--icon-color, ${colors.close})
      }
    </style>
    <svg
      width="${size}"
      height="${size}"
      viewBox="-4 0 19 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 0c.265 0 .52.105.707.293l5 5a1 1 0 11-1.414 1.414L6 2.414 1.707 6.707A1 1 0 11.293 5.293l5-5A.997.997 0 016 0" fill-rule="evenodd"/>
    </svg>
    `;
}
