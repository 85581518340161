import {I18n} from 'common/translator/i18n';
import {constraintWidthInViewport} from 'common/utils/constraintWidthInViewport';

import {
  createLoginButton,
  LoginWithShopButton,
} from '../components/login-with-shop-button';
import {
  ATTRIBUTE_ANALYTICS_TRACE_ID,
  ATTRIBUTE_ANCHOR_SELECTOR,
} from '../../../constants/loginButton';
import {
  DefaultComponentAnalyticsContext,
  getShopLoginDefaultTemplate,
  FOOTER_CLASS_NAME,
  FOOTER_COMPACT_CLASS_NAME,
  FOOTER_CONTENT_CLASS_NAME,
  HEADER_CLASS_NAME,
  HEADER_COMPACT_CLASS_NAME,
  HEADER_LOGO_COMPACT_CLASS_NAME,
  HEADER_CONTENTS_CONTAINER_CLASS_NAME,
  HEADER_CONTENTS_CONTAINER_COMPACT_CLASS_NAME,
  HEADER_DESCRIPTION_CLASS_NAME,
  HEADER_DIVIDER_CLASS_NAME,
  HEADER_LOGO_COMPACT_HTML,
  HEADER_USER_CARD_HTML,
  HEADER_USER_CARD_CLASS_NAME,
  HEADER_EMAIL_ACTION_CLASS_NAME,
  HEADER_EMAIL_ACTION_OPEN_CLASS_NAME,
  HEADER_AVATAR_CLASS_NAME,
  HEADER_EMAIL_CLASS_NAME,
  HEADER_HIDDEN_CLASS_NAME,
  HEADER_STYLE,
  HEADER_TITLE_CLASS_NAME,
  HIDDEN_ELEMENT_CLASS_NAME,
  IFRAME_CLASS_NAME,
  IFRAME_COMPACT_CLASS_NAME,
  STORE_NAME_TEMPLATE,
} from '../../../constants/loginDefault';
import {
  ATTRIBUTE_COMPACT,
  ShopSheetModal,
} from '../../../common/shop-sheet-modal/shop-sheet-modal';
import {ChevronDown, ChevronUp} from '../../../common/svg';
import {LoginWithShopSdkPageName} from '../../../common/analytics';
import {copyTemplateToDom, updateAttribute} from '../../../common/utils';
import MonorailTracker from '../analytics/DefaultComponentMonorailTracker';
import {
  AuthorizeModalTextActionType,
  AuthenticationLevel,
  ModalCustomizations,
  AuthorizeModalTextViewState,
  AuthorizeModalTextAction,
  AuthorizeModalTextStep,
} from '../../../types';
import {sanitizeModalText as sanitizeModalCustomization} from '../utils/modalCustomizations';
import {
  checkoutModalReducer,
  classicCustomerAccountsSignUpReducer,
  DEFAULT_STATE,
  defaultEmailAuthReducer,
  defaultPhoneAuthReducer,
  paymentRequestReducer,
} from '../utils/authorizeModalTextReducer';

interface SetupOptions {
  onOpen: () => void;
  onClose: () => void;
  onResetOpen?: () => void;
  onResetClose?: () => void;
}
export default class LoginDefaultView {
  private _rootElement: ShadowRoot;
  private _isCompact = false;

  private _onOpen: () => void;
  private _onClose: () => void;
  private _onResetOpen: (() => void) | undefined;
  private _onResetClose: (() => void) | undefined;

  private _sheetModal!: ShopSheetModal;
  private _modalHeader: HTMLDivElement | undefined;
  private _headerIframe: HTMLIFrameElement | undefined;
  private _headerContentsContainer: HTMLDivElement | undefined;
  private _headerLogo: HTMLImageElement | undefined;
  private _headerTitle: HTMLElement | undefined;
  private _headerShopLogo: HTMLElement | undefined;
  private _headerDescription: HTMLElement | undefined;
  private _headerUserCard: HTMLElement | undefined;
  private _headerUserCardAction: HTMLElement | undefined;

  private _footerElement: HTMLDivElement | undefined;
  private _footerContent: HTMLDivElement | undefined;

  private _iframe: HTMLIFrameElement | undefined;
  private _headerResizeObserver: ResizeObserver | undefined;

  private _modalTitle: string | undefined;
  private _modalDescription: string | undefined;
  private _modalLogoSrc: string | undefined;

  private _continueButton: LoginWithShopButton | undefined;

  private _monorailTracker: MonorailTracker | undefined;
  private _analyticsContext = DefaultComponentAnalyticsContext.Default;
  private _flowVersion = 'unspecified';
  private _authenticationLevel: AuthenticationLevel = AuthenticationLevel.Phone;
  private _flow: string | undefined;

  private _i18n: I18n | undefined;
  private _state: AuthorizeModalTextViewState = DEFAULT_STATE;

  constructor(rootElement: ShadowRoot, options: SetupOptions) {
    this._rootElement = rootElement;

    ({
      onOpen: this._onOpen,
      onClose: this._onClose,
      onResetOpen: this._onResetOpen,
      onResetClose: this._onResetClose,
    } = options);

    if (!customElements.get('shop-chevron-down')) {
      customElements.define('shop-chevron-down', ChevronDown);
    }
    if (!customElements.get('shop-chevron-up')) {
      customElements.define('shop-chevron-up', ChevronUp);
    }
  }

  setCompact(isCompact: boolean) {
    this._isCompact = Boolean(isCompact);
  }

  init() {
    copyTemplateToDom(
      getShopLoginDefaultTemplate(this._isCompact ? 'compact' : ''),
      'shop-login-default-landing',
      this._rootElement,
    );

    this._sheetModal = this._rootElement.querySelector('shop-sheet-modal')!;
    this._modalHeader = this._rootElement.querySelector(
      `.${HEADER_CLASS_NAME}`,
    )! as HTMLDivElement;
    this._footerElement = this._rootElement.querySelector(
      `.${FOOTER_CLASS_NAME}`,
    )! as HTMLDivElement;
    this._footerContent = this._rootElement.querySelector(
      `.${FOOTER_CONTENT_CLASS_NAME}`,
    )! as HTMLDivElement;
    this._iframe = this._rootElement.querySelector(
      `.${IFRAME_CLASS_NAME}`,
    )! as HTMLIFrameElement;

    this._sheetModal.addEventListener('modalcloserequest', () => {
      this.closeAuthorizeModal();
    });

    if (this._isCompact) {
      this._sheetModal.setAttribute(ATTRIBUTE_COMPACT, '');
      this._modalHeader.classList.add(HEADER_COMPACT_CLASS_NAME);
      this._modalHeader.classList.remove(HEADER_DIVIDER_CLASS_NAME);
      this._iframe.classList.add(IFRAME_COMPACT_CLASS_NAME);
      this._footerElement.classList.add(FOOTER_COMPACT_CLASS_NAME);
    }

    updateAttribute(this._iframe, 'allow', 'publickey-credentials-get *');
  }

  setModalAnalyticsTraceId(analyticsTraceId: string): void {
    this._sheetModal.setAttribute(
      ATTRIBUTE_ANALYTICS_TRACE_ID,
      analyticsTraceId,
    );
  }

  async showModal() {
    const result = await this._sheetModal.open();
    if (result) {
      this._onOpen();
    }
    this.refreshHeaderIframeHeight();
  }

  async openAuthorizeModal() {
    this.showModal();
    this._monorailTracker?.trackPageImpression({
      page: LoginWithShopSdkPageName.AuthorizeModal,
    });
  }

  async closeAuthorizeModal() {
    if (this._sheetModal) {
      const result = await this._sheetModal.close();
      if (result) {
        this._onClose();
      }
    }
    this._continueButton?.setFocused();
  }

  setContinueButtonVisible(isVisible: boolean) {
    if (!isVisible) {
      this._continueButton?.remove();
      this._continueButton = undefined;
      return;
    }

    if (!this._continueButton) {
      this._continueButton = createLoginButton();
      this._continueButton?.addEventListener('click', () => {
        this._monorailTracker?.trackLoginDefaultButtonClicked();
        this.openAuthorizeModal();
      });

      this._rootElement.appendChild(this._continueButton);
    }
  }

  setMonorailTracker(monorailTracker: MonorailTracker) {
    this._monorailTracker = monorailTracker;
  }

  setAnalyticsContext(analyticsContext: DefaultComponentAnalyticsContext) {
    this._analyticsContext = analyticsContext;
  }

  setFlowVersion(flowVersion: string) {
    this._flowVersion = flowVersion;
  }

  setAuthenticationLevel(authenticationLevel: AuthenticationLevel) {
    this._authenticationLevel = authenticationLevel;
  }

  setFlow(flow: string) {
    this._flow = flow;
  }

  getIframe(): HTMLIFrameElement | undefined {
    return this._iframe;
  }

  setAnchorSelector(anchorSelector: string) {
    this._sheetModal.setAttribute(ATTRIBUTE_ANCHOR_SELECTOR, anchorSelector);
  }

  setBrand(brand?: string) {
    if (brand) {
      this._sheetModal.setAttribute('modal-brand', brand);
    }
  }

  resizeIframe(height: number, width: number) {
    if (!this._iframe) return;

    this._iframe.style.height = `${height}px`;
    this._iframe.style.width = `${constraintWidthInViewport(width, this._iframe)}px`;
  }

  render() {
    const {configurable, headerVisible, headerDividerVisible} = this._state;

    this.setHeaderVisible(headerVisible);
    if (!this._isCompact) {
      this.setHeaderDividerVisible(headerDividerVisible);
    }

    if (configurable && this._modalLogoSrc) {
      this.updateHeaderLogo(this._modalLogoSrc);
    }

    this.renderHeaderTemplate();
    this.renderHeaderDescriptionTemplate();
    this.renderFooterContent();

    this.refreshHeaderIframeHeight();
  }

  dispatch(action: AuthorizeModalTextAction) {
    if (action.type === AuthorizeModalTextActionType.Restart) {
      this.cleanUIOnRestart();
    }
    if (
      [
        AuthorizeModalTextActionType.Restart,
        AuthorizeModalTextActionType.VerificationStepChanged,
      ].includes(action.type)
    ) {
      this.closeUserCardAction();
    }

    switch (this._analyticsContext) {
      case DefaultComponentAnalyticsContext.CheckoutExtension:
      case DefaultComponentAnalyticsContext.Default:
        this._state =
          this._authenticationLevel === AuthenticationLevel.Phone
            ? defaultPhoneAuthReducer(action, this._state)
            : defaultEmailAuthReducer(action, this._state);
        break;
      case DefaultComponentAnalyticsContext.ClassicCustomerAccounts:
        this._state =
          this._flowVersion === 'sign_up'
            ? classicCustomerAccountsSignUpReducer(action, this._state)
            : defaultPhoneAuthReducer(action, this._state);
        break;
      case DefaultComponentAnalyticsContext.CheckoutModal:
        this._state = checkoutModalReducer(action, this._state);
        break;
      case DefaultComponentAnalyticsContext.PaymentRequest:
        this._state = paymentRequestReducer(action, this._state);
        break;
      default:
        this._state = DEFAULT_STATE;
        break;
    }

    this.render();
  }

  cleanUIOnRestart() {
    if (this._headerUserCard) {
      this._headerUserCardAction?.removeEventListener('click', () =>
        this.toggleUserCardAction(),
      );
      this._headerContentsContainer?.removeChild(this._headerUserCard);
      this._headerUserCard = undefined;
    }
  }

  updateHeaderLogo(src: string) {
    if (!this._headerLogo) {
      this._headerLogo = document.createElement('img');

      const firstChild = this._headerContentsContainer?.firstChild;
      if (firstChild) {
        this._headerContentsContainer?.insertBefore(
          this._headerLogo,
          firstChild,
        );
      }
    }

    this._headerLogo.src = src;
    this._headerLogo.style.maxHeight = '64px';
    this._headerLogo.style.width = 'auto';
  }

  /**
   * toggle between title view and user card view
   * @param {boolean} showUserCard - whether the user card should be visible or not
   */
  toggleUserCard(showUserCard: boolean) {
    this._headerShopLogo?.classList.toggle(
      HEADER_HIDDEN_CLASS_NAME,
      showUserCard,
    );
    this._headerTitle?.classList.toggle(HEADER_HIDDEN_CLASS_NAME, showUserCard);
    this._headerUserCard?.classList.toggle(
      HEADER_HIDDEN_CLASS_NAME,
      !showUserCard,
    );
  }

  closeUserCardAction() {
    this._headerUserCardAction?.classList.remove(
      HEADER_EMAIL_ACTION_OPEN_CLASS_NAME,
    );
  }

  toggleUserCardAction() {
    if (!this._isCompact) return;

    if (
      this._headerUserCardAction?.classList.contains(
        HEADER_EMAIL_ACTION_OPEN_CLASS_NAME,
      )
    ) {
      this._onResetClose?.();
    } else {
      this._onResetOpen?.();
    }
    this._headerUserCardAction?.classList.toggle(
      HEADER_EMAIL_ACTION_OPEN_CLASS_NAME,
    );
  }

  renderHeaderTemplate() {
    const {configurable, headerTemplate, templateVariables} = this._state;

    if (this._isCompact && templateVariables.email) {
      this.renderHeaderUserCard();
      this.toggleUserCard(true);

      return;
    }

    this.toggleUserCard(false);
    let headerTitle = '';

    if (configurable && this._modalTitle) {
      headerTitle = this._modalTitle.replaceAll(
        STORE_NAME_TEMPLATE,
        templateVariables.clientName,
      );
    } else if (headerTemplate) {
      headerTitle =
        this._i18n?.translate(headerTemplate, templateVariables) || '';
    }

    if (typeof headerTitle === 'string') {
      this._headerTitle!.textContent = headerTitle;
    }
  }

  renderHeaderUserCard() {
    if (!this._headerContentsContainer || this._headerUserCard) return;

    this._headerContentsContainer.classList.add(HIDDEN_ELEMENT_CLASS_NAME);
    const {templateVariables} = this._state;

    this._headerUserCard = document.createElement('div');
    this._headerUserCard.classList.add(HEADER_USER_CARD_CLASS_NAME);
    this._headerUserCard.innerHTML = HEADER_USER_CARD_HTML;

    const avatar = this._headerUserCard.querySelector(
      `.${HEADER_AVATAR_CLASS_NAME}`,
    )! as HTMLDivElement;
    avatar.textContent = templateVariables?.email?.[0] || '';

    const emailTextSpan = this._headerUserCard.querySelector(
      `.${HEADER_EMAIL_CLASS_NAME} span`,
    )! as HTMLSpanElement;
    emailTextSpan.textContent = templateVariables?.email || '';

    this._headerContentsContainer.appendChild(this._headerUserCard);

    this._headerUserCardAction = this._headerUserCard.querySelector(
      `.${HEADER_EMAIL_ACTION_CLASS_NAME}`,
    )! as HTMLDivElement;
    this._headerUserCardAction?.addEventListener('click', () =>
      this.toggleUserCardAction(),
    );
  }

  renderHeaderDescriptionTemplate() {
    if (this._isCompact) return;

    const {configurable, descriptionTemplate, templateVariables} = this._state;
    let headerDescription = '';

    if (configurable && this._modalDescription) {
      headerDescription = this._modalDescription?.replaceAll(
        STORE_NAME_TEMPLATE,
        templateVariables.clientName,
      );
    } else if (descriptionTemplate) {
      headerDescription =
        this._i18n?.translate(descriptionTemplate, templateVariables) || '';
    }

    if (typeof headerDescription === 'string') {
      this._headerDescription!.textContent = headerDescription;
    }
  }

  renderFooterContent() {
    if (!this._i18n) return;

    const {step, templateVariables, userNameKnown, sessionDetected} =
      this._state;
    const {clientName, privacyPolicyUrl, termsOfServiceUrl} = templateVariables;
    let content = '';

    switch (step) {
      /**
       * For these steps, we need to render the legal text specific for the client
       * e.g., By continuing, your email address will be shared with...
       */
      case AuthorizeModalTextStep.PersonalizeConsent:
      case AuthorizeModalTextStep.EmailVerification:
      case AuthorizeModalTextStep.PhoneVerification:
      case AuthorizeModalTextStep.WebAuthnVerification:
      case AuthorizeModalTextStep.OneClick: {
        // Personalize Consent should only render legal text when a user cookie exists
        if (
          step === AuthorizeModalTextStep.PersonalizeConsent &&
          !sessionDetected
        ) {
          break;
        }

        // Shop Pay Commerce Component does not render legal text for these steps
        if (
          this._analyticsContext ===
          DefaultComponentAnalyticsContext.PaymentRequest
        ) {
          break;
        }

        const authorizeText = this._i18n.translate(
          userNameKnown
            ? 'legal.authorized_scopes.email_name'
            : 'legal.authorized_scopes.email',
          templateVariables,
        );

        /**
         * If a client does not have a privacy policy or terms of service, we should not render the client's
         * legal links.
         */
        if (!privacyPolicyUrl || !termsOfServiceUrl) {
          content = authorizeText;
          break;
        }

        const clientLegalText = this._i18n.translate(`legal.client`, {
          clientName,
          privacyPolicy: `<a href="${privacyPolicyUrl}" target="_blank">${this._i18n.translate(
            'legal.privacy_policy',
          )}</a>`,
          termsOfService: `<a href="${termsOfServiceUrl}" target="_blank">${this._i18n.translate(
            'legal.terms',
          )}</a>`,
        });

        content = `${authorizeText} ${clientLegalText}`;
        break;
      }

      /**
       * For the Sign Up step, a user must agree to Shop's terms and conditions and privacy policy
       */
      case AuthorizeModalTextStep.SignUp: {
        const shopTermsOfServiceLink = `<a href="https://shop.app/terms-of-service" target="_blank">${this._i18n.translate(
          'legal.terms_of_service',
        )}</a>`;
        const privacyPolicyLink = `<a href="https://www.shopify.com/legal/privacy/app-users" target="_blank">${this._i18n.translate(
          'legal.privacy_policy',
        )}</a>`;
        content = this._i18n.translate(`legal.shop`, {
          clientName,
          termsOfService: shopTermsOfServiceLink,
          privacyPolicy: privacyPolicyLink,
        });

        break;
      }
    }

    this._footerContent!.innerHTML = content;
    this.setFooterVisible(Boolean(content));
  }

  setHeaderVisible(isVisible: boolean) {
    this._modalHeader!.classList.toggle(HIDDEN_ELEMENT_CLASS_NAME, !isVisible);

    if (!this._headerIframe) {
      this._headerIframe = this._rootElement.querySelector(
        `.${HEADER_CLASS_NAME}-container`,
      )! as HTMLIFrameElement;
      const headerContainerIframeBody =
        this._headerIframe.contentDocument?.querySelector('body')!;
      headerContainerIframeBody.innerHTML = HEADER_STYLE;

      this._headerContentsContainer = document.createElement('div');
      this._headerContentsContainer.classList.add(
        HEADER_CONTENTS_CONTAINER_CLASS_NAME,
      );

      this._headerTitle = document.createElement('h2');
      this._headerTitle.classList.add(HEADER_TITLE_CLASS_NAME);
      this._headerContentsContainer.appendChild(this._headerTitle);

      if (this._isCompact) {
        this._headerShopLogo = document.createElement('div');
        this._headerShopLogo.innerHTML = HEADER_LOGO_COMPACT_HTML;
        this._headerShopLogo.classList.add(HEADER_LOGO_COMPACT_CLASS_NAME);
        this._headerContentsContainer.prepend(this._headerShopLogo);
        this._headerContentsContainer.classList.add(
          HEADER_CONTENTS_CONTAINER_COMPACT_CLASS_NAME,
        );
        // if compact, we're not showing description in the header
      } else {
        this._headerDescription = document.createElement('div');
        this._headerDescription.classList.add(HEADER_DESCRIPTION_CLASS_NAME);
        this._headerContentsContainer.appendChild(this._headerDescription);
      }

      if (this._flow === 'pop_up') {
        this._headerContentsContainer.style.display = 'flex';
        this._headerContentsContainer.style.flexDirection = 'column';
      }

      headerContainerIframeBody.appendChild(this._headerContentsContainer);

      if (window.ResizeObserver) {
        this._headerResizeObserver = new ResizeObserver(() => {
          this.refreshHeaderIframeHeight();
        });
        this._headerResizeObserver.observe(this._headerContentsContainer);
      } else {
        this.refreshHeaderIframeHeight();
      }
    }
  }

  /**
   * Resize iframe to match height of the modal header content
   */
  refreshHeaderIframeHeight() {
    const headerContainerIframeBody =
      this._headerIframe?.contentDocument?.querySelector('body')!;

    const heightStyle = `${
      this._headerContentsContainer?.getBoundingClientRect().height
    }px`;

    headerContainerIframeBody?.setAttribute('height', heightStyle);
    this._headerIframe?.setAttribute('height', heightStyle);
  }

  setHeaderDividerVisible(isVisible: boolean) {
    this._modalHeader!.classList.toggle(HEADER_DIVIDER_CLASS_NAME, isVisible);
  }

  setFooterVisible(isVisible: boolean) {
    this._footerElement!.classList.toggle(
      HIDDEN_ELEMENT_CLASS_NAME,
      !isVisible,
    );
  }

  setCustomizedModalContent({
    modalTitle,
    modalDescription,
    modalLogo,
  }: ModalCustomizations) {
    if (modalTitle) {
      this._modalTitle = sanitizeModalCustomization(modalTitle);
    }
    if (modalDescription) {
      this._modalDescription = sanitizeModalCustomization(modalDescription);
    }
    if (modalLogo) {
      this._modalLogoSrc = sanitizeModalCustomization(modalLogo);
    }
  }

  getCustomizedModalContent() {
    return {
      modalTitle: this._modalTitle,
      modalDescription: this._modalDescription,
      modalLogo: this._modalLogoSrc,
    };
  }

  setTranslations(i18n: I18n) {
    this._i18n = i18n;
  }

  isModalCustomized() {
    return Boolean(
      this._modalTitle || this._modalDescription || this._modalLogoSrc,
    );
  }
}
