/**
 * @file Utility file which creates a singleton instance
 * used for communicating between components via a pub/sub mechanism
 */

export enum ShopHubTopic {
  UserStatusIdentity = 'userstatuschange:identity',
  UserStatusScope = 'userstatuschange:scope',
  UserSessionCreate = 'usersession:create',
}

interface GenericTopicPayload {}

interface ShopHubTopicPayloadLookup {
  [ShopHubTopic.UserStatusIdentity]: GenericTopicPayload;
  [ShopHubTopic.UserStatusScope]: GenericTopicPayload;
  [ShopHubTopic.UserSessionCreate]: {
    avatar?: string;
    initial: string;
    email?: string;
  };
}

/**
 * The payload for a given topic, or the union of all payloads if no topic is specified
 * Usage:
 *  ShopHubPayload<ShopHubTopic.UserSessionCreate> // UserSessionCreatePayload
 *  ShopHubPayload<ShopHubTopic.UserStatusIdentity> // GenericTopicPayload
 *  ShopHubPayload // GenericTopicPayload | UserSessionCreatePayload | ...
 */
export type ShopHubPayload<
  TTopicType extends ShopHubTopic | undefined = undefined,
> = TTopicType extends keyof ShopHubTopicPayloadLookup
  ? ShopHubTopicPayloadLookup[TTopicType]
  : ShopHubTopicPayloadLookup[keyof ShopHubTopicPayloadLookup];

interface ShopHubTopicEntry<TTopicType extends ShopHubTopic> {
  publisherId: string;
  callback: (payload: ShopHubPayload<TTopicType>) => void;
}
type ShopHubTopicEntries = {
  [key in ShopHubTopic]?: ShopHubTopicEntry<key>[];
};

export const ShopHub = (() => {
  class ShopSingleton {
    private _topics: ShopHubTopicEntries;

    constructor() {
      this._topics = {};
    }

    subscribe<TTopicType extends ShopHubTopic>(
      topic: TTopicType,
      publisherId: string,
      callback: (payload: ShopHubPayload<TTopicType>) => void,
    ): void {
      this._topics[topic] = [
        ...(this._topics[topic] || []),
        {
          publisherId,
          callback,
        },
      ] as ShopHubTopicEntries[TTopicType];
    }

    unsubscribe<TTopicType extends ShopHubTopic>(
      topic: TTopicType,
      publisherId: string,
    ): void {
      this._topics[topic] = (this._topics[topic] || []).filter(
        (entry) => entry.publisherId !== publisherId,
      ) as ShopHubTopicEntries[TTopicType];
    }

    unsubscribeAll(publisherId: string): void {
      Object.keys(this._topics).forEach((topic: string): void => {
        this.unsubscribe(topic as ShopHubTopic, publisherId);
      });
    }

    publish<TTopicType extends ShopHubTopic>(
      topic: TTopicType,
      publisherId: string,
      payload: ShopHubPayload<TTopicType>,
    ) {
      this._topics[topic]?.forEach((entry) => {
        if (entry.publisherId === publisherId) return;

        entry.callback(payload);
      });
    }
  }

  let instance: ShopSingleton;

  return {
    getInstance: (): ShopSingleton => {
      if (!instance) {
        instance = new ShopSingleton();
      }

      return instance;
    },
  };
})();
