import {v4 as uuidv4} from 'uuid';

import {POPUP_CLOSE_CHECK_INTERVAL, SPIN_URL_SUFFIX} from '../constants';

import {StoreMetadata} from './types';

const getWindowPosition = () => {
  const left =
    window.screenLeft === undefined ? window.screenX : window.screenLeft;
  const top =
    window.screenTop === undefined ? window.screenY : window.screenTop;
  return {
    left,
    top,
  };
};

export const getWindowSize = () => {
  const width = window.innerWidth || document.documentElement.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight;
  return {
    width,
    height,
  };
};

const getSystemZoom = () => {
  return screen.width && window.screen.availWidth
    ? screen.width / window.screen.availWidth
    : 1;
};

interface PopupParameters {
  url: string;
  width: number;
  height: number;
  windowName?: string;
  onClose?: () => void;
}

/**
 * Opens a popup widow. Tries to put the window into the middle of the parent,
 * if possible. Otherwise puts it into the middle of the screen.
 * @param {object} root The root parameter object.
 * @param {string} root.url URL to display in the popup.
 * @param {number} root.width Target width of the popup.
 * @param {number} root.height Target height of the popup.
 * @param {string} root.windowName Optional name given to the popup window.
 *    Should not contain whitespace. This will not be used as the window's title.
 * @param {Function} root.onClose Optional function called when the popup is closed.
 * @returns {Window} A reference to the popup window object.
 */
export function openPopupWindow({
  url,
  width,
  height,
  windowName,
  onClose,
}: PopupParameters): Window | null {
  const windowPosition = getWindowPosition();
  const windowSize = getWindowSize();

  const clientWidth = windowSize.width || screen.width;
  const clientHeight = windowSize.height || screen.height;

  const systemZoom = getSystemZoom();
  const left = (clientWidth - width) / 2 / systemZoom + windowPosition.left;
  const top = (clientHeight - height) / 2 / systemZoom + windowPosition.top;

  const popupWindow: Window | null = window.open(
    url,
    windowName,
    `scrollbars=yes,width=${width},height=${height},top=${top},left=${left}`,
  );
  if (!popupWindow) return null;
  popupWindow.focus();

  if (onClose) {
    const closeCheckInterval = setInterval(() => {
      if (!popupWindow.closed) return;
      onClose();
      clearInterval(closeCheckInterval);
    }, POPUP_CLOSE_CHECK_INTERVAL);
  }

  return popupWindow;
}

/**
 * Extracts the Spin URL suffix (i.e. ".c66z.alex.us.spin.dev") from the current location or extracts it from a
 * "spin" param (e.g., https://mytunnelvision.dev?spin=.c66z.alex.us.spin.dev)
 * @returns {string | null} the Spin URL suffix or null if it's not running on Spin.
 */
export function extractSpinUrlSuffix(): string | null {
  // eslint-disable-next-line no-process-env
  const onSpin = process.env.NODE_ENV === 'spin';
  if (onSpin) {
    const spinSuffix = new URLSearchParams(window.location.search).get('spin');
    if (spinSuffix) return spinSuffix;
  }

  const found = window.location.origin.match(SPIN_URL_SUFFIX);
  return found ? found[0] : null;
}

/**
 * Updates the value on an HTML element if it's different to the current one.
 * @param {HTMLElement} element The HTML element to update.
 * @param {string} attribute The name of the attribute to set.
 * @param {string} value The new value for the attribute.
 * @param {boolean | undefined} forced whether to force a reload even if the URL matches
 */
export function updateAttribute(
  element: HTMLElement,
  attribute: string,
  value: string,
  forced?: boolean,
) {
  if (!forced && element.getAttribute(attribute) === value) return;
  if ((element as Record<string, any>)[attribute] === true) return;
  element.setAttribute(attribute, value);
}

/**
 * Updates the iframe src without pushing to the browser history stack. Does this by
 * removing the iframe from the DOM, updating the src attribute and then re-adding it.
 * @param {HTMLIFrameElement} iframe The iframe to navigate.
 * @param {string} src The new URL to navigate to.
 * @param {boolean | undefined} forced Whether to force a reload even if the URL matches.
 */
export function updateIframeSrc(
  iframe: HTMLIFrameElement,
  src: string,
  forced?: boolean,
) {
  if (!forced && iframe.src === src) return;

  const parent = iframe.parentNode;
  if (!parent) return;

  parent.removeChild(iframe);
  iframe.setAttribute('src', src);
  parent.appendChild(iframe);
}

/**
 * Returns the UUID to be used as the Analytics Trace ID, to link events from one session together.
 * @returns {string} The Analytics Trace ID.
 */
export function getAnalyticsTraceId(): string {
  return uuidv4();
}

/**
 * Returns the duration in milliseconds between two dates, if defined.
 * @param {Date} from The earlier date
 * @param {Date} to The later date
 * @returns {number | undefined} The duration in ms between the given dates or undefined if it's not possible to calculate.
 */
export function getDuration(from?: Date, to?: Date): number | undefined {
  if (!from || !to) return undefined;
  return to.getTime() - from.getTime();
}

/**
 * Gets the store's metadata from meta.json file.
 * @param {string} storefrontOrigin The origin of the storefront.
 * @returns {Promise<StoreMetadata | null>} The store's metadata, or null if it's not available.
 */
export async function getStoreMeta(
  storefrontOrigin: string = location.origin,
): Promise<StoreMetadata | null> {
  const response = fetch(`${storefrontOrigin}/meta.json`);
  try {
    const res = await response;
    const meta = await res.json();
    return meta;
  } catch {
    return null;
  }
}

/**
 * @param {string} storeUrl store url (e.g. `https://merchant.com` or
 *     `http://store.myshopify.com`). Will validate that it's a valid URL and not
 *     a subset.
 * @returns {string} The extracted hostname of the provided URL, or null if an error occurs in parsing hostname
 */
export function getHostName(storeUrl: string): string | null {
  try {
    return new URL(storeUrl).hostname;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`[Shop Pay] Store URL (${storeUrl}) is not valid`, error);
    return null;
  }
}

/**
 *
 *Extract a query parameter from the current URL.
 * @param {string} name The name of the query parameter to get.
 * @returns {string | null} The value of the query parameter or null if it doesn't exist.
 */
export function getQueryParam(name: string): string | null {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
}

/**
 * Climbs the DOM tree from the given shadow root to the document body. Yielding each HTML element along the way.
 * @param {ShadowRoot | Element} base The shadow root or element to start climbing from.
 * @yields {Element} The next HTML element in the DOM tree.
 */
export function* climbDomTree(base: ShadowRoot | Element): Generator<Element> {
  let current: ShadowRoot | Element | null = base;

  while (current) {
    if (current.parentElement) {
      current = current.parentElement;
    } else if (current instanceof ShadowRoot) {
      current = current.host;
    } else if (current instanceof Element) {
      const root = current.getRootNode();
      if (root instanceof ShadowRoot) {
        current = root.host;
      } else {
        break;
      }
    } else {
      break;
    }

    yield current;

    if (current === document.body) {
      break;
    }
  }
}

type Callback<T extends any[]> = (...args: T) => void;
/**
 * Debounces a function call by the given timeout.
 * @param {Callback} callback The function to call after the timeout has elapsed.
 * @param {number} timeout The timeout in milliseconds.
 * @returns {Callback} A function that will call the callback after the timeout has elapsed.
 */
export function debounce<T extends any[]>(
  callback: Callback<T>,
  timeout: number,
): (...args: T) => void {
  let timer: NodeJS.Timeout;

  return (...args: T) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback(...args);
    }, timeout);
  };
}

/**
 *
 *Remove trailing slash from a URL. If the URL is only a slash, it will be returned as is.
 * @param {string} urlString The URL to remove the trailing slash from.
 * @returns {string} The URL without the trailing slash.
 */
export function removeTrailingSlash(urlString: string): string {
  if (urlString === '/') return urlString;
  return urlString.endsWith('/') ? urlString.slice(0, -1) : urlString;
}

/**
 * Returns `true` if the given URLs are considered to have matching root domains. A "match"
 * in this case means that one domain is either a superdomain or a subdomain of the other.
 *
 * This solves the specific case of allowing postMessaging between domains like `lang.storefront.com`
 * and `storefront.com`. However once Sign in with Shop can be deployed on any surface this restriction
 * may need to be reconsidered.
 * @param {string} urlString1 A URL with a root domain or subdomain (i.e. "http://merchant.com" or "http://fr.merchant.com")
 * @param {string} urlString2 A URL with a root domain or subdomain (i.e. "http://merchant.com" or "http://fr.merchant.com")
 * @returns {boolean} `true` if the root domains of the given URLs match
 */
export function isRootDomainMatch(
  urlString1: string,
  urlString2: string,
): boolean {
  try {
    const host1Tokens = new URL(urlString1).host.split('.').reverse();
    const host2Tokens = new URL(urlString2).host.split('.').reverse();
    for (let i = 0; i < Math.min(host1Tokens.length, host2Tokens.length); i++) {
      if (host1Tokens[i] !== host2Tokens[i]) {
        return false;
      }
    }
    return true;
  } catch (err) {
    return false;
  }
}

/**
 * Applies a partial set of styles to an element.
 * @param {HTMLElement} element The element to apply the styles to.
 * @param {Partial<CSSStyleDeclaration>} styles The (partial) styles to apply to the element.
 * @returns {CSSStyleDeclaration} The element's style object.
 */
export function applyStylesToElement(
  element: HTMLElement,
  styles: Partial<CSSStyleDeclaration>,
): CSSStyleDeclaration {
  return Object.assign(element.style, styles);
}

/**
 * Detect if user is using mobile (logic inspired from Core)
 * @returns {boolean} Whether the user is using a mobile browser
 */
export function isMobileBrowser(): boolean {
  return (
    Boolean(navigator.userAgent) &&
    /(android|iphone|ipad|mobile|phone)/i.test(navigator.userAgent)
  );
}

/**
 * Detect specifically if this is an iOS platform
 * @returns {boolean} Whether the device is running iOS
 */
export function isIOSPlatform(): boolean {
  return (
    Boolean(navigator.userAgent) &&
    /(iphone|ipad|ipod)/i.test(navigator.userAgent)
  );
}

/**
 * Checks whether the given value passed to a component attribute is empty.
 * @param {string} value The value passed to a component attribute.
 * @returns {boolean} `true` if the given value is null or empty, `false` otherwise.
 */
export function isEmptyAttributeValue(value: string | null) {
  return value === null || value.trim() === '';
}

/**
 * A function that resolves a promise with a max timeout
 * This function was written by Chat-GPT3.5. Prompt by gioele
 * @param {Promise} promise the promise to race with a timeout
 * @param {number} timeoutMs the timeout in milliseconds
 * @returns {Promise} either the resolved promise or a rejected promise with a timeout error
 */
export function promiseWithTimeout<T>(
  promise: Promise<T>,
  timeoutMs: number,
): Promise<T | void> {
  let timeoutId: NodeJS.Timeout;

  const timeoutPromise = new Promise<void>((resolve) => {
    timeoutId = setTimeout(() => {
      resolve();
    }, timeoutMs);
  });

  return Promise.race([promise, timeoutPromise]).finally(() => {
    clearTimeout(timeoutId);
  });
}

/**
 *
 */
export function getFeatures(): Record<string, any> {
  const featuresTag = document.querySelector(
    'script#shop-js-features',
  )?.innerHTML;
  if (featuresTag) {
    return JSON.parse(featuresTag);
  } else {
    return {};
  }
}
