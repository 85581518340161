import {ShopHubTopic, ShopHubPayload} from '../../common/utils';
import ConnectedWebComponent from '../../common/ConnectedWebComponent';
import {defineCustomElement} from '../../common/init';
import {ShopUserAvatarAttribute} from '../../types';

import {AvatarView} from './view/AvatarView';

export default class ShopUserAvatar extends ConnectedWebComponent {
  static observedAttributes = [
    ShopUserAvatarAttribute.Source,
    ShopUserAvatarAttribute.Initial,
  ];

  private _view?: AvatarView;

  connectedCallback() {
    if (!this.shadowRoot) {
      const shadowRoot = this.attachShadow({mode: 'open'});
      const avatarSrc = this.getAttribute(ShopUserAvatarAttribute.Source) || '';
      const initial = this.getAttribute(ShopUserAvatarAttribute.Initial) || '';
      this._view = new AvatarView(avatarSrc, initial, shadowRoot);
      this.subscribeToHub(
        ShopHubTopic.UserSessionCreate,
        this._handleUserSessionCreated.bind(this),
      );
    }

    this._view!.render();
  }

  disconnectedCallback(): void {
    this._view?.destroy();
  }

  attributeChangedCallback(
    name: string,
    _oldValue: string,
    newValue: string | null,
  ): void {
    const value = newValue || '';

    switch (name) {
      case ShopUserAvatarAttribute.Source:
        this._view?.updateAvatarSrc(value);
        break;
      case ShopUserAvatarAttribute.Initial:
        this._view?.updateInitial(value);
        break;
    }
  }

  private _handleUserSessionCreated({
    avatar,
    initial,
  }: ShopHubPayload<ShopHubTopic.UserSessionCreate>) {
    this.setAttribute(ShopUserAvatarAttribute.Source, avatar || '');
    this.setAttribute(ShopUserAvatarAttribute.Initial, initial);
  }
}

/**
 * Defines the shop-user-avatar custom element.
 */
export function defineElement() {
  defineCustomElement('shop-user-avatar', ShopUserAvatar);
}
