/**
 * @file Base class for components with access to the ShopHub singleton
 */
import {v4 as uuidv4} from 'uuid';

import {ShopUserAvatarAttribute} from '../types';

import WebComponent from './WebComponent';
import {ShopHub, ShopHubPayload, ShopHubTopic} from './utils';

export default abstract class ConnectedWebComponent extends WebComponent {
  private _shopHub = ShopHub.getInstance();
  private _publisherId: string = uuidv4();

  protected subscribeToHub<TTopicType extends ShopHubTopic>(
    topic: TTopicType,
    callback: (payload: ShopHubPayload<TTopicType>) => void,
  ): void {
    this._shopHub.subscribe(topic, this._publisherId, callback);
  }

  protected unsubscribeAllFromHub(): void {
    this._shopHub.unsubscribeAll(this._publisherId);
  }

  protected unsubscribeFromHub(topic: ShopHubTopic): void {
    this._shopHub.unsubscribe(topic, this._publisherId);
  }

  protected publishToHub<TTopicType extends ShopHubTopic>(
    topic: TTopicType,
    payload: ShopHubPayload<TTopicType>,
  ): void {
    this._shopHub.publish(topic, this._publisherId, payload);

    // eslint-disable-next-line no-warning-comments
    // TODO: The ConnectedWebComponent shouldn't be responsible for avatar updates, we should refactor this to
    // decouple the avatar logic. https://github.com/Shopify/shop-js/issues/2085
    if (topic === ShopHubTopic.UserSessionCreate) {
      this.dispatchCustomEvent(ShopHubTopic.UserSessionCreate, payload);
      this._dispatchUserAvatarChangeEvent(
        payload as ShopHubPayload<ShopHubTopic.UserSessionCreate>,
      );
    }
  }

  private _dispatchUserAvatarChangeEvent(
    payload: ShopHubPayload<ShopHubTopic.UserSessionCreate>,
  ) {
    const {avatar, initial} = payload;
    const avatarElement = document.createElement('shop-user-avatar');
    avatarElement.setAttribute(ShopUserAvatarAttribute.Source, avatar || '');
    avatarElement.setAttribute(ShopUserAvatarAttribute.Initial, initial);

    this.dispatchCustomEvent('shop:useravatarchange', {
      avatar: avatarElement,
    });
  }
}
